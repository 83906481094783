import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-elearning-banner',
    templateUrl: './elearning-banner.component.html',
    styleUrls: ['./elearning-banner.component.scss']
})
export class ElearningBannerComponent implements OnInit {
    userData: any = {}
    isLogin: boolean = false
    courses: any = []
    pathServer = environment.API_URL
    constructor (private http: HttpClient) {
        const API_URL = environment.API_URL
        this.http.get(API_URL + '/api/v1/on-top').subscribe((response: any) => {
            this.courses = response
        })

        const token = document.cookie
            ? document.cookie
                  ?.split(';')
                  ?.filter(item => item.trim().startsWith('token='))[0]
                  ?.split('=')[1]
            : ''
        if (!token) {
            this.isLogin = false
            return
        } else {
            this.isLogin = true
            this.userData = JSON.parse(localStorage.getItem('userData'))
        }
    }

    ngOnInit (): void {}

    bgImage = [
        {
            img: 'assets/img/gray-bg.jpg'
        }
    ]
}
