import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-courses-style-one',
    templateUrl: './courses-style-one.component.html',
    styleUrls: ['./courses-style-one.component.scss']
})
export class CoursesStyleOneComponent implements OnInit {
    classId: string = ''
    subjectId: string = ''
    classes: any = {}
    courses: any = []
    subjectName: string = ''
    loading: boolean = true

    constructor (
        private title: Title,
        private http: HttpClient,
        private router: Router
    ) {}
    ngOnInit (): void {
        this.getCourseDetails() // Load course details when component initializes

        // if change any event
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCourseDetails()
                this.scrollToTopSmoothly()
            }
        })
    }

    getCourseDetails (): void {
        //'class/:id/subject/:subjectId'
        const url = this.router.url

        //get :id from url using regex
        this.classId = url.match(/class\/(\d+)/)[1]
        //get :subjectId from url using regex
        this.subjectId = url.match(/subject\/(\d+)/)[1]

        const token: string = new Date().getTime().toString()
        const API_URL = environment.API_URL
        this.http
            .get(
                `${API_URL}/api/v1/contentapis?type=getClassById&id=${this.classId}`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => {
                if (res.length === 0) {
                    this.router.navigate(['/'])
                }
                this.classes = res[0]
                this.loading = false
            })

        this.http
            .get(
                `${API_URL}/api/v1/contentapis?type=getCoursesBySubjectId&classId=${this.classId}&subjectId=${this.subjectId}`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => {
                this.courses = res.courses
                this.subjectName = res.subject
                this.title.setTitle(
                    this.subjectName + ' | ' + environment.siteName
                )
            })
    }

    //remove html tags from string
    removeTags (str: string): string | boolean {
        if (str === null || str === '') {
            return false
        } else {
            str = str.toString()
        }
        //remove &nbsp and &amp from string
        str = str.replace(/&nbsp;/g, ' ')
        str = str.replace(/&amp;/g, '&')
        return str.replace(/<[^>]*>/g, '').substring(0, 100) + '...'
    }

    private scrollToTopSmoothly (): void {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100)
    }
}
