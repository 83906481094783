import { HttpClient } from '@angular/common/http'
import { Component, OnInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    // Navbar Sticky
    isSticky: boolean = false
    isLogin: boolean = false
    userData: any = {}
    openMenu: boolean = false
    overlay: boolean = false
    loading: boolean = true
    showSearch: boolean = false
    courses: any = []
    keyword: string = ''
    @HostListener('window:scroll', ['$event'])
    checkScroll () {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0
        if (scrollPosition >= 50) {
            this.isSticky = true
        } else {
            this.isSticky = false
        }
    }

    getCookie (name: string) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    //get token from cookie with name 'token'
    token = document.cookie ? this.getCookie('token') : ''

    constructor (private http: HttpClient, private router: Router) {
        if (!this.token) {
            this.isLogin = false
            this.loading = false
            return
        }
        const API_URL = environment.API_URL
        http.post(API_URL + '/api/v1/loginRegister', {
            type: 'checkToken',
            token: this.token
        }).subscribe((response: any) => {
            if (response.status) {
                this.isLogin = true
                this.userData = {
                    name: response.fullname,
                    role: response.role
                }
                this.loading = false
                localStorage.setItem('userData', JSON.stringify(this.userData))
            }
        })
    }

    ngOnInit (): void {}

    classApplied = false
    toggleClass () {
        this.classApplied = !this.classApplied
    }

    logout () {
        const API_URL = environment.API_URL
        this.http
            .post(API_URL + '/api/v1/loginRegister', {
                type: 'logout',
                token: this.token
            })
            .subscribe((response: any) => {
                if (response.status) {
                    this.isLogin = false
                    this.userData = {}
                    document.cookie =
                        'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                    this.overlay = false
                    this.openMenu = false
                    window.location.href = '/'
                }
            })
    }
    openMenuMethod () {
        this.openMenu = !this.openMenu
        this.overlay = !this.overlay
    }

    searchCourse (event: any) {
        if (event.target.value.length > 2) {
            //searchOnCourses
            this.keyword = event.target.value
            const token: string = new Date().getTime().toString()
            const API_URL = environment.API_URL
            this.http
                .get(
                    `${API_URL}/api/v1/contentapis?type=searchOnCourses&search=${this.keyword}`,
                    {
                        headers: {
                            'x-auth-token': token
                        }
                    }
                )
                .subscribe((res: any) => {
                    this.showSearch = true
                    if (res.length === 0) {
                        this.courses = []
                        return
                    }
                    //set courses 10 items
                    this.courses = res.slice(0, 10)
                })
        } else {
            this.courses = []
            this.showSearch = false
        }
    }
    getToCourse (id: string) {
        this.showSearch = false
        this.keyword = ''
        this.openMenu = false
        //redirect to course page /course/' + id with refresh
        window.location.href = '/course/' + id
    }
}
