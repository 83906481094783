<div class="main-banner" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <h1>رُبّ هِمّةٍ أَحْيَتْ أُمَّةً.</h1>
                    <p>منصة مختصة في إنشاء المحتوى التعليمي وتنمية القدرات والمهارات</p>

                    <a *ngIf="!isLogin" routerLink="/authentication" class="default-btn"><i
                            class="flaticon-user"></i>إنضم لنا<span></span></a>
                    <div *ngIf="isLogin" class="logedinNav">
                        <i class="flaticon-user"></i>
                        مرحبا
                        {{userData?.name}}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-courses-list">
                    <div class="row">

                        <!-- //if courses is empty -->
                        <div *ngIf="courses.length == 0" class="col-lg-12 col-md-12">
                           <!-- جاري التحميل -->
                           <div class="spinner-border text-success" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6" *ngFor="let course of courses">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a [routerLink]="['/course', course.nid]" class="d-block image">
                                        <img [src]="pathServer+course.field_cu_pic" [alt]="course.title">
                                    </a>
                                    <a [routerLink]="['/course', course.nid]" class="fav"><i
                                            class="flaticon-heart"></i></a>
                                    <div class="price newItem shadow">
                                        {{course.field_status == '1' ? 'مدفوع' : 'مجاني'}}
                                    </div>
                                </div>
                                <div class="courses-content">

                                    <h3><a routerLink="/course/{{course.nid}}">
                                            {{course.title}}
                                        </a></h3>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="banner-shape1"><img src="assets/img/banner-shape1.png" alt="image"></div>
                    <div class="banner-shape2"><img src="assets/img/instructor-home/banner/shape1.png" alt="image">
                    </div>
                    <div class="banner-shape3"><img src="assets/img/banner-shape3.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>