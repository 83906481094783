<div class="kitchen-feedback-area pb-70">
    <div class="container">
        <div class="kitchen-feedback-slides">
            <owl-carousel-o [options]="kitchenFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-kitchen-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user/user7.png" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kitchen-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user/user8.png" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kitchen-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user/user9.png" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="container pt-70">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3><span class="odometer" [countUp]="3279">00</span></h3>
                        <p>Enrolled Learners</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3><span class="odometer" [countUp]="250">00</span></h3>
                        <p>Online Instructors</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3><span class="odometer" [countUp]="1926">00</span></h3>
                        <p>Finished Sessions</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3><span class="odometer" [countUp]="100">00</span>%</h3>
                        <p>SATISFACTION RATE</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>