<div class="oa-events-area ptb-100">
    <div class="container">
        <div class="section-title oa-title-color">
            <span class="sub-title">UPCOMING EVENTS</span>
            <h2>Check Out Our Latest Events</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="oa-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/online-art/events/events1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Online Art</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">The Plaza Art Fair</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2024</li>
                            <li><i class='bx bx-map'></i> New York, US</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="oa-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/online-art/events/events2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Online Art</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">Park Forest Fine Art Fair</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2024</li>
                            <li><i class='bx bx-map'></i> New York, US</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="oa-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/online-art/events/events3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Online Art</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">Port Clinton Art Festival</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2024</li>
                            <li><i class='bx bx-map'></i> New York, US</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="oa-events-info">
                    <p>Get into details now?​ <a routerLink="/events">View all events</a></p>
                </div>
            </div>
        </div>
    </div>
</div>