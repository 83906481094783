import { Component, OnInit } from '@angular/core'
import lgVideo from 'lightgallery/plugins/video'
import { BeforeSlideDetail } from 'lightgallery/lg-events'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    homePageVideo: any = {}

    constructor (http: HttpClient) {
        const API_URL = environment.API_URL
        http.get(API_URL + '/api/v1/home-page-video').subscribe(
            (response: any) => {
                this.homePageVideo = response[0] 
            }
        )
    }

    ngOnInit (): void {}

    settings = {
        counter: false,
        plugins: [lgVideo]
    }
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail
        console.log(index, prevIndex)
    }
}
