<div class="page-title-area">
    <div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
        <div class="spinner-border text-success" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
    <div class="container" *ngIf="!loading">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>الملف الشخصي</li>
            </ul>
            <h2>الملف الشخصي</h2>
            <div class="profile-box">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2">
                        <div class="photoProfileContainer">
                            <img [src]="userData?.photo == 'https://cms.hemamacademy.com' ? 'assets/img/profile.png': userData?.photo"
                                alt=" image" class="photoProfile">
                            <a href="javascript:void(0)" class="edit-profile-photo" (click)="changePhoto()">
                                <i class='bx bx-camera'></i>
                            </a>

                            <img src="assets/img/loading.gif" alt="loading" class="loading" *ngIf="loadingPhoto" />

                        </div>
                    </div>
                    <div class="col-lg-10 col-md-10">
                        <div class="content">
                            <h3>{{getRoleName(userData?.role)}}: {{userData?.fullname}}</h3>
                            <ul class="info">
                                <li>
                                    البريد الإلكتروني:
                                    {{userData?.email}}
                                </li>
                                <li>
                                    أخر تسجيل دخول:
                                    <!-- convert userData?.last timestamp to int and then to date -->
                                    {{userData?.last }}

                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" shape9"><img src="assets/img/shape8.svg" alt="image">
    </div>
</div>

<div class="profile-area pb-70" *ngIf="!loading">
    <div class="container">
        <div class="profile-courses-quizzes">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        الدورات
                    </span>
                </li>
                <li *ngIf="userData?.role=='teacher'" class="nav-tab" [ngClass]="{'active': currentTab === 'student'}">
                    <span (click)="switchTab($event, 'student')">
                        طلابي
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        المعلومات الشخصية
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}" *ngIf="userData?.role=='student'">
                    <span (click)="switchTab($event, 'tab3')">
                        معلومات الإشتراك
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        كلمة المرور
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1' && !expPopup">

                    <div class="tabs-container">
                        <div class="pane" id="tab-pane1" *ngIf="currentTab2 === 'tab-pane1'">

                            <div class="tabs-container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img1.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <div class="course-author d-flex align-items-center">
                                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                                    <span>Alex Morgan</span>
                                                </div>
                                                <h3><a routerLink="/single-courses-1">The Data
                                                        Science Course 2024:
                                                        Complete Data Science Bootcamp</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img2.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <div class="course-author d-flex align-items-center">
                                                    <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                                    <span>Sarah Taylor</span>
                                                </div>
                                                <h3><a routerLink="/single-courses-1">Java
                                                        Programming Masterclass
                                                        for Software Developers</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img3.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <div class="course-author d-flex align-items-center">
                                                    <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                                    <span>David Warner</span>
                                                </div>
                                                <h3><a routerLink="/single-courses-1">Deep
                                                        Learning A-Z™: Hands-On
                                                        Artificial Neural Networks</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img6.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/single-courses-1">Machine
                                                        Learning
                                                        Practical:Real world Projects in
                                                        Healthcare</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img7.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/single-courses-1">Teach Your
                                                        Kids to Code: Learn
                                                        Python Programming at Any Age</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-courses-box">
                                            <div class="courses-image">
                                                <a routerLink="/single-courses-1" class="d-block image">
                                                    <img src="assets/img/courses/img8.jpg" alt="image">
                                                </a>
                                                <a routerLink="/single-courses-1" class="fav"><i
                                                        class="flaticon-heart"></i></a>

                                            </div>
                                            <div class="courses-content">
                                                <h3><a routerLink="/single-courses-1">Data
                                                        Science 2024 : Complete
                                                        Data Science & Machine Learning</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et
                                                    dolore.</p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="container">
                        <form (ngSubmit)="updateProfile()" [formGroup]="userForm">
                            <div *ngFor="let sucMsg of sucMsgs">
                                <div class="alert alert-success mt-3" *ngIf="sucMsg.key === 'updateProfile'">
                                    <span>{{sucMsg.message}}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>الأسم الثلاثي</label>
                                <input type="text" class="form-control" placeholder="الأسم الثلاثي"
                                    formControlName="fullName">

                                <div *ngIf="userForm?.get('fullName')?.invalid && userForm?.get('fullName')?.touched"
                                    class="text-danger">
                                    <div *ngIf="userForm?.get('fullName')?.errors?.required">هذا الحقل مطلوب </div>
                                    <div *ngIf="userForm?.get('fullName')?.errors?.minlength">الأسم يجب أن يكون أكثر
                                        من
                                        3
                                        حروف</div>
                                    <div *ngIf="userForm?.get('fullName')?.errors?.pattern">الأسم يجب أن يكون حروف
                                        فقط
                                    </div>
                                </div>

                            </div>
                            <div class="form-group">
                                <label>رقم الهاتف</label>
                                <input type="text" class="form-control" placeholder="رقم الهاتف"
                                    formControlName="phone">

                                <div *ngIf="userForm?.get('phone')?.invalid && userForm?.get('phone')?.touched"
                                    class="text-danger">
                                    <div *ngIf="userForm?.get('phone')?.errors?.required">هذا الحقل مطلوب </div>
                                    <div *ngIf="userForm?.get('phone')?.errors?.minlength">رقم الهاتف يجب أن يكون
                                        أكثر
                                        من
                                        10
                                        أرقام</div>
                                    <div *ngIf="userForm?.get('phone')?.errors?.pattern">رقم الهاتف يجب أن يكون
                                        أرقام
                                        فقط
                                    </div>
                                </div>
                            </div>
                            <div class="form-group ">
                                <label class="ml-3">تاريخ الميلاد</label>
                                <mat-form-field class="form-group">
                                    <input matInput [matDatepicker]="picker" placeholder="إختر تاريخ الميلاد"
                                        class="form-control w-100 mt-3" formControlName="dob"
                                        max="{{maxDate | date:'yyyy-MM-dd'}}">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                                <div *ngIf="userForm?.get('dob')?.invalid && userForm?.get('dob')?.touched"
                                    class="text-danger">
                                    <div *ngIf="userForm?.get('dob')?.errors?.required">هذا الحقل مطلوب </div>
                                </div>

                            </div>

                            <div class="form-group">
                                <button type="submit" class="btn btn-primary mt-3" [disabled]="userForm.invalid">تحديث
                                    البيانات</button>
                                <div *ngFor="let error of errors" class="alert alert-danger mt-3">
                                    <span *ngIf="error.key === 'updateprofile'">{{error.message}}</span>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="container">

                        <div class="input-group input-group-lg">
                            <span class="input-group-text" id="inputGroup-sizing-lg">رقم البطاقة</span>
                            <input type="text" class="form-control" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" value="{{userData?.card}}" disabled>
                        </div>

                        <div class="input-group input-group-lg mt-3">
                            <span class="input-group-text" id="inputGroup-sizing-lg">تاريخ الإشتراك </span>
                            <input type="text" class="form-control" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" value="{{userData?.start}}" disabled>
                        </div>
                        <div class="input-group input-group-lg mt-3">
                            <span class="input-group-text" id="inputGroup-sizing-lg">تاريخ الإنتهاء</span>
                            <input type="text" class="form-control" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" value="{{userData?.exp}}" disabled>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <form (ngSubmit)="resetpassword()" [formGroup]="userFormResetPassword">
                        <div *ngFor="let sucMsg of sucMsgs">
                            <div class="alert alert-success mt-3" *ngIf="sucMsg.key === 'resetpassword'">
                                <span>{{sucMsg.message}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>كلمة المرور الحالية</label>
                            <input type="password" class="form-control" placeholder="كلمة المرور الحالية"
                                formControlName="oldPassword">
                            <div *ngIf="userFormResetPassword?.get('oldPassword')?.invalid && userFormResetPassword?.get('oldPassword')?.touched"
                                class="text-danger">
                                <div *ngIf="userFormResetPassword?.get('oldPassword')?.errors?.required">هذا الحقل مطلوب
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>كلمة المرور الجديدة</label>
                            <input type="password" class="form-control" placeholder="كلمة المرور الجديدة"
                                formControlName="newPassword">
                            <div *ngIf="userFormResetPassword?.get('newPassword')?.invalid && userFormResetPassword?.get('newPassword')?.touched"
                                class="text-danger">
                                <div *ngIf="userFormResetPassword?.get('newPassword')?.errors?.required">هذا الحقل مطلوب
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>
                                تأكيد كلمة المرور
                            </label>
                            <input type="password" class="form-control" placeholder="تأكيد كلمة المرور"
                                formControlName="confirmPassword">
                            <div *ngIf="userFormResetPassword?.get('confirmPassword')?.invalid && userFormResetPassword?.get('confirmPassword')?.touched"
                                class="text-danger">
                                <div *ngIf="userFormResetPassword?.get('confirmPassword')?.errors?.required">هذا الحقل
                                    مطلوب
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mt-3"
                                [disabled]="userFormResetPassword.invalid">تغيير كلمة المرور</button>
                            <div *ngFor="let error of errors" class="alert alert-danger mt-3">
                                <span *ngIf="error.key === 'resetpassword'">{{error.message}}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="pane" id="student" *ngIf="currentTab === 'student'">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>الأسم</th>
                                <th>البريد الإلكتروني</th>
                                <th>الهاتف</th>
                                <th>التاريخ</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>الأسم</td>
                                <td>البريد الإلكتروني</td>
                                <td>الهاتف</td>
                                <td>التاريخ</td>

                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="table-dark">
                                <td colspan="4">المجموع 1</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>


    </div>

    <div class="expoverlay" *ngIf="expPopup">
        <div class="expPopup">
            <h3>لقد إنتهت صلاحية الإشتراك الخاص بك يرجى تجديد الإشتراك!</h3>
            <form class="mt-3" (ngSubmit)="renewSubscription()" [formGroup]="renewForm">
                <div class="form-group">
                    <label>رقم البطاقة</label>
                    <input type="text" class="form-control" placeholder="رقم البطاقة" formControlName="cardNumber">
                    <div *ngIf="renewForm?.get('cardNumber')?.invalid && renewForm?.get('cardNumber')?.touched"
                        class="text-danger">
                        <div *ngIf="renewForm?.get('cardNumber')?.errors?.required">هذا الحقل مطلوب </div>
                    </div>
                </div>
                <div class="btns">
                    <button type="submit" class="btn btn-primary mt-3" [disabled]="renewForm.invalid">تجديد
                        الإشتراك</button>
                    <button type="button" class="btn btn-danger mt-3" (click)="payCard()">
                        إشتري بطاقة جديدة
                    </button>
                </div>

                <div *ngFor="let error of errors" class="alert alert-danger mt-3">
                    <span *ngIf="error.key === 'renewSubscription'">{{error.message}}</span>
                </div>
                <div *ngFor="let sucMsg of sucMsgs">
                    <div class="alert alert-success mt-3" *ngIf="sucMsg.key === 'renewSubscription'">
                        <span>{{sucMsg.message}}</span>
                    </div>
                    <button type="button" class="btn btn-primary mt-3" (click)="closeExpPopup()">إغلاق</button>
                </div>
            </form>
        </div>
    </div>