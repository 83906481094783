import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-distance-learning',
    templateUrl: './distance-learning.component.html',
    styleUrls: ['./distance-learning.component.scss']
})
export class DistanceLearningComponent implements OnInit {
    pageTitle = 'الصفحة الرئيسية' + ' | ' + environment.siteName

    constructor (private title: Title) {
        this.title.setTitle(this.pageTitle)
    }

    ngOnInit (): void {
        this.title.setTitle(this.pageTitle)
    }
}
