import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd } from '@angular/router'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PpagesComponent implements OnInit {
    pageContent: any = {}
    siteName: string = ''
    loadingpages: boolean = true
    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private http: HttpClient,
        private router: Router
    ) {}
    ngOnInit (): void {
        this.getContent()
        // Subscribe to router events
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getContent()
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }, 100)
            }
        })
    }

    getContent () {
        this.loadingpages = true
        this.siteName = environment.siteName
        //get about frq
        let pageID = this.route.snapshot.paramMap.get('id')
        const apiUrl = environment.API_URL + '/api/v1/pages/' + pageID
        this.http.get(apiUrl).subscribe((data: any) => {
            if (data.length === 0) {
                return
            }
            this.pageContent = data[0]
            this.title.setTitle(
                this.pageContent['title'] + ' | ' + environment.siteName
            )
            this.loadingpages = false
        })
    }
}
