<div class="categories-area">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">مدرسة هِمَم</span>
            <h2>صفوف هِمَم</h2>
            <p> اختر الصف الذي ترغب في الدراسة فيه</p>
        </div>

        <!-- map in categories array -->
        <div class="row">
            <div class="col-lg-2 col-sm-6 col-md-6" *ngFor="let category of classes">
                <div class="single-categories-box">
                    <img src="assets/img/shape22.png" alt="image">
                    <div class="content">
                        <h3>{{category.name}}</h3>
                    </div>
                    <a [routerLink]="'class/'+category.id" class="link-btn"></a>
                </div>
            </div>

        </div>
    </div>
</div>