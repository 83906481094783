<div class="about-area bg-fef8ef ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img3.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/about-img4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">لماذا تختار</span>
                    <h2>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Trainers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Acces</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>