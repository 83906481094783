import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-blog-details-style-one',
    templateUrl: './blog-details-style-one.component.html',
    styleUrls: ['./blog-details-style-one.component.scss']
})
export class BlogDetailsStyleOneComponent implements OnInit {
    blog: any = {}
    loading: boolean = true
    url: string = ''

    extractTags (arabicString: string): string[] {
        const tagRegex = /(?:\s|^)(ل|إ)([\u0600-\u06FF]+)(?:\s|$)/g // Regular expression to match tags starting with "ل" or "إ" followed by Arabic characters
        const matches = arabicString.match(tagRegex)
        if (matches) {
            // Filter out any duplicate tags
            const uniqueTags = Array.from(
                new Set(matches.map(match => match.trim()))
            )
            // Remove the "ل" or "إ" character from the beginning of each tag
            return uniqueTags.map(tag => tag.substring(1))
        }
        return []
    }
    getBlogDetails () {
        this.loading = true
        this.blog = {}
        const API_URL =
            environment.API_URL +
            '/api/v1/blogs?id=' +
            this.route.snapshot.params.id +
            '&type=getSingleBlog'
        this.http.get(API_URL).subscribe((data: any) => {
            if (!data.status) {
                // navigate to 404
                this.router.navigate(['/404'])
            }
            this.title.setTitle(data.data.title + ' | ' + environment.siteName)
            this.blog = data.data
            this.loading = false
            //get full url
            this.url = window.location.href
            // this.meta.updateTag({ name: 'description', content: data[0].description })
            // this.meta.updateTag({ name: 'keywords', content: data[0].keywords })
            let tags = this.extractTags(data.data.body)
            this.meta.updateTag({ name: 'keywords',  content: tags.join(',') })
            this.meta.updateTag({ name: 'description', content: data.data.body.substring(0, 150) })
            //setBlogView
            this.http
                .get(
                    environment.API_URL +
                        '/api/v1/blogs?id=' +
                        this.route.snapshot.params.id +
                        '&type=setBlogView'
                )
                .subscribe((data: any) => {
                    if (!data.status) {
                        return
                    }
                })
        })
    }

    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private http: HttpClient,
        private router: Router
    ) {
        this.getBlogDetails()
    }

    ngOnInit (): void {
        // Subscribe to router events
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getBlogDetails()
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }
                , 100)
            }
        })
    }
}
