<div class="container" [ngClass]="{'d-none': router.url === '/online-art' || router.url === '/single-instructor'}">
    <div class="section-title">
        <span class="sub-title">الأخبار والمدونات</span>
        <h2>أحدث منشوراتنا</h2>
        <p>نحن دائمًا نولي اهتمامًا إضافيًا لتحسين مهارات طلابنا ونشعر بالحماس لمشاركة أحدث أبحاثنا وتعلمنا!</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
            <div class="single-blog-post">
                <div class="post-image">
                    <a [routerLink]="'/single-blog/'+ blog?.nid" class="d-block">
                        <img [src]="blog.field_images_export[0]" [alt]="blog.title" [title]="blog.title" />
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">{{blog?.field_lnwaa}}</a>
                    <h3><a [routerLink]="'/single-blog/'+ blog?.nid">{{blog?.title}}</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li> </li>
                        <li><i class='flaticon-calendar'></i> {{blog?.created}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="blog-post-info">
                <p>

                    <a routerLink="/blogs">عرض جميع المنشورات</a>
                </p>
            </div>
        </div>
    </div>
</div>