import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component'
import { VendorCertificationEtrainingComponent } from './components/pages/vendor-certification-etraining/vendor-certification-etraining.component'
import { OnlineTrainingSchoolComponent } from './components/pages/online-training-school/online-training-school.component'
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component'
import { LanguageSchoolComponent } from './components/pages/language-school/language-school.component'
import { ModernSchoolingComponent } from './components/pages/modern-schooling/modern-schooling.component'
import { YogaTrainingComponent } from './components/pages/yoga-training/yoga-training.component'
import { HealthCoachingComponent } from './components/pages/health-coaching/health-coaching.component'
import { KindergartenComponent } from './components/pages/kindergarten/kindergarten.component'
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component'
import { GalleryComponent } from './components/pages/gallery/gallery.component'
import { AboutStyleOneComponent } from './components/pages/about-style-one/about-style-one.component'
import { SuccessStoryComponent } from './components/pages/success-story/success-story.component'
import { TeacherComponent } from './components/pages/teacher/teacher.component'
import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component'
import { ErrorComponent } from './components/pages/error/error.component'
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component'
import { PurchaseGuideComponent } from './components/pages/purchase-guide/purchase-guide.component'
import { PayCardComponent } from './components/pages/payCard/payCard.component'
import { CoursesStyleOneComponent } from './components/pages/courses-style-one/courses-style-one.component'
import { CoursesStyleTwoComponent } from './components/pages/courses-style-two/courses-style-two.component'
import { CoursesStyleThreeComponent } from './components/pages/courses-style-three/courses-style-three.component'
import { CoursesStyleFourComponent } from './components/pages/courses-style-four/courses-style-four.component'
import { CoursesStyleFiveComponent } from './components/pages/courses-style-five/courses-style-five.component'
import { CoursesStyleSixComponent } from './components/pages/courses-style-six/courses-style-six.component'
import { CoursesStyleSevenComponent } from './components/pages/courses-style-seven/courses-style-seven.component'
import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component'
import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component'
import { ProfileComponent } from './components/pages/profile/profile.component'
import { ProfileQuizComponent } from './components/pages/profile-quiz/profile-quiz.component'
import { MembershipLevelsComponent } from './components/pages/membership-levels/membership-levels.component'
import { BecomeATeacherComponent } from './components/pages/become-a-teacher/become-a-teacher.component'
import { CategoriesComponent } from './components/pages/categories/categories.component'
import { EventsComponent } from './components/pages/events/events.component'
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component'
import { ProductsListStyleOneComponent } from './components/pages/products-list-style-one/products-list-style-one.component'
import { ProductsListStyleTwoComponent } from './components/pages/products-list-style-two/products-list-style-two.component'
import { CartComponent } from './components/pages/cart/cart.component'
import { CheckoutComponent } from './components/pages/checkout/checkout.component'
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component'
import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component'
import { BlogDetailsStyleOneComponent } from './components/pages/blog-details-style-one/blog-details-style-one.component'
import { GymCoachingComponent } from './components/pages/gym-coaching/gym-coaching.component'
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component'
import { BusinessCoachingComponent } from './components/pages/business-coaching/business-coaching.component'
import { MotivationalCoachingComponent } from './components/pages/motivational-coaching/motivational-coaching.component'
import { KitchenCoachingComponent } from './components/pages/kitchen-coaching/kitchen-coaching.component'
import { OnlineArtComponent } from './components/pages/online-art/online-art.component'
import { SingleInstructorComponent } from './components/pages/single-instructor/single-instructor.component'
// teacher-page
import { TeacherPageComponent } from './components/pages/teacher-page/teacher-page.component'
import { environment } from 'src/environments/environment.prod'

//PpagesComponent
import { PpagesComponent } from './components/pages/pages/pages.component'
const routes: Routes = [
    {
        path: '',
        component: ElearningSchoolComponent,
        //change page title
        data: { title: 'الصفحة الرئيسية' + ' | ' + environment.siteName }
    },
    {
        path: 'about',
        component: AboutStyleOneComponent,
        //change page title
        data: { title: 'من نحن' + ' | ' + environment.siteName }
    },
    {
        path: 'authentication',
        component: ProfileAuthenticationComponent,
        //change page title
        data: { title: 'تسجيل الدخول' + ' | ' + environment.siteName }
    },
    {
        path: 'payCard',
        component: PayCardComponent,
        data: { title: 'نقاط بيع بطاقات هِمَم' + ' | ' + environment.siteName }
    },
    {
        path: 'contact',
        component: ContactUsComponent,
        data: { title: 'اتصل بنا' + ' | ' + environment.siteName }
    },
    {
        path: 'class/:id',
        component: CategoriesComponent,
        data: { title: 'المواد الدراسية ' + ' | ' + environment.siteName }
    },
    { path: 'teacher/:id', component: TeacherPageComponent },
    { path: 'course/:id', component: CoursesDetailsStyleOneComponent },

    { path: 'profile', component: ProfileComponent },
    {
        path: 'class/:id/subject/:subjectId',
        component: CoursesStyleOneComponent
    },

    { path: 'single-blog/:id', component: BlogDetailsStyleOneComponent },

    { path: 'blogs', component: BlogStyleOneComponent },

    /////end of teacher-page

    { path: 'purchase-guide', component: PurchaseGuideComponent },
    {
        path: 'vendor-certification-etraining',
        component: VendorCertificationEtrainingComponent
    },
    {
        path: 'online-training-school',
        component: OnlineTrainingSchoolComponent
    },

    { path: 'distance-learning', component: DistanceLearningComponent },
    { path: 'language-school', component: LanguageSchoolComponent },
    { path: 'modern-schooling', component: ModernSchoolingComponent },
    { path: 'yoga-training', component: YogaTrainingComponent },
    { path: 'health-coaching', component: HealthCoachingComponent },
    { path: 'kindergarten', component: KindergartenComponent },
    { path: 'gym-coaching', component: GymCoachingComponent },
    { path: 'learning-management', component: LearningManagementComponent },
    { path: 'business-coaching', component: BusinessCoachingComponent },
    { path: 'motivational-coaching', component: MotivationalCoachingComponent },
    { path: 'kitchen-coaching', component: KitchenCoachingComponent },
    { path: 'online-art', component: OnlineArtComponent },
    { path: 'single-instructor', component: SingleInstructorComponent },
    { path: 'success-story', component: SuccessStoryComponent },
    { path: 'teachers', component: TeacherComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'courses-1', component: CoursesStyleOneComponent },
    { path: 'courses-2', component: CoursesStyleTwoComponent },
    { path: 'courses-3', component: CoursesStyleThreeComponent },
    { path: 'courses-4', component: CoursesStyleFourComponent },
    { path: 'courses-5', component: CoursesStyleFiveComponent },
    { path: 'courses-6', component: CoursesStyleSixComponent },
    { path: 'courses-7', component: CoursesStyleSevenComponent },
    { path: 'single-courses-1', component: CoursesDetailsStyleOneComponent },
    { path: 'single-courses-2', component: CoursesDetailsStyleTwoComponent },
    { path: 'profile-quiz', component: ProfileQuizComponent },
    { path: 'membership-levels', component: MembershipLevelsComponent },
    { path: 'become-a-teacher', component: BecomeATeacherComponent },

    { path: 'events', component: EventsComponent },
    { path: 'single-events', component: EventsDetailsComponent },
    { path: 'products-list-1', component: ProductsListStyleOneComponent },
    { path: 'products-list-2', component: ProductsListStyleTwoComponent },
    { path: 'cart', component: CartComponent },
    { path: 'checkout', component: CheckoutComponent },
    { path: 'single-products', component: ProductsDetailsComponent },
    { path: 'gallery', component: GalleryComponent },
    // Here add new pages component

    { path: ':id', component: PpagesComponent },
    { path: '**', component: ErrorComponent }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
