<div class="motivation-feedback-area ptb-100">
    <div class="container">
        <div class="motivation-feedback-slides">
            <owl-carousel-o [options]="motivationFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-motivation-feedback-box">
                        <div class="client-info d-flex align-items-center justify-content-center">
                            <img src="assets/img/user/user1.jpg" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-motivation-feedback-box">
                        <div class="client-info d-flex align-items-center justify-content-center">
                            <img src="assets/img/user/user2.jpg" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-motivation-feedback-box">
                        <div class="client-info d-flex align-items-center justify-content-center">
                            <img src="assets/img/user/user3.jpg" alt="user">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>