<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!loading">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>{{pageContent?.title}}</li>
                </ul>
                <h2>{{siteName}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-12 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">{{pageContent?.title}}</span>
                        <h2>{{pageContent?.field_parts_export[0]?.laanwn}} </h2>
                        <div [innerHTML]="pageContent?.field_parts_export[0]?.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    </div>


    <div class="features-area pb-70">
        <app-features-style-one></app-features-style-one>
    </div>
</div>