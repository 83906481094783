<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<div *ngIf="!loading">

    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>نقاط بيع بطاقات هِمَم</li>
                </ul>
                <h2>نقاط بيع بطاقات هِمَم</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="faq-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <select class="form-control selectCustom" aria-label="Default select example"
                        (change)="selectCity($event)" [ngModel]="cityValue">

                        <option selected value="all">جميع المحافظات</option>
                        <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                    </select>
                </div>
                <div class="col-lg-6 col-md-6">
                    <input type="text" class="form-control" placeholder="المنطقة..." (keyup)="searchPos($event)">
                </div>
            </div>
            <div class="row">
                <!-- table -->
                <hr class="mt-3">
                <table class="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th scope="col">اسم نقطة البيع</th>
                            <th scope="col">المحافظة</th>
                            <th scope="col">المنطقة</th>
                            <th scope="col">رقم الهاتف</th>
                            <th scope="col">الموقع</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let arry of pos" class="text-right">
                            <td class="p-3">{{arry.title}}</td>
                            <td class="p-3">{{arry.field_city}}</td>
                            <td class="p-3">{{arry.field_state}}</td>
                            <td class="p-3">{{arry.field_rqm_lhtf}}</td>
                            <td class="p-3">
                                <button class="btn btn-sm btn-secondary"
                                    (click)="showMap(arry.field_location_map)">اظهار
                                    الموقع</button>
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
        </div>

    </div>


    <div class="popupMap" *ngIf="showmappopup">
        <div class="popupMapContent">
            <span class="popupMapClose" (click)="showmappopup = false">&times;</span>


            <google-map height="400px" width="800px" [center]="{ lat: mapData.lat, lng: mapData.lon }">
                <map-marker [position]="{ lat: mapData.lat, lng: mapData.lon }"></map-marker>
            </google-map>

        </div>
    </div>

</div>