<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!loading">

    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li><a [routerLink]="'/class/'+course?.classID">{{course?.class}}</a></li>
                    <li><a
                            [routerLink]="'/class/'+course?.classID+'/subject/'+course?.subjectID">{{course?.subject}}</a>
                    </li>
                    <li>{{course?.name}}</li>
                </ul>
                <h2>{{course?.name}}</h2>

            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="courses-details-area pb-100">
        <div class="courses-details-image">
            <img [src]="course?.photo" alt="image">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-details-desc">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span (click)="switchTab($event, 'tab1')">
                                    الوصف
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                                <span (click)="switchTab($event, 'tab2')">
                                    الدروس
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                                <span (click)="switchTab($event, 'tab3')">
                                    المعلم
                                </span>
                            </li>

                        </ul>
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <div class="courses-overview" [innerHTML]="course?.description">
                                </div>
                            </div>
                            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                                <div class="courses-curriculum">
                                    <!-- map in course.videosLength -->
                                    <div *ngFor="let video of course?.videosLength; let i = index">
                                        <ul>
                                            <li><a href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                                    class="popup-youtube d-flex justify-content-between align-items-center">
                                                    <span class="courses-name">{{video.name}}</span>
                                                    <div class="courses-meta">
                                                        <span class="status"> إستعراض </span>
                                                        <span class="status locked"><i
                                                                class="flaticon-password"></i></span>

                                                    </div>
                                                </a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                                <div class="courses-instructor">
                                    <div class="single-advisor-box">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4">
                                                <div class="advisor-image">
                                                    <img [src]="course?.teacher?.photo" alt="image">
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-8">
                                                <div class="advisor-content">
                                                    <h3>{{course?.teacher?.name}}</h3>
                                                    <span class="sub-title">{{course?.teacher?.details}}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-details-info">
                        <div class="image">

                            <video id="video"></video>
                            <div class="overlay d-flex justify-content-center align-items-center">
                                <i class="flaticon-play text-white" *ngIf="!isPlaying && !videoLoaded"
                                    (click)="playVideo()"></i>
                                <div class="copyRight" *ngIf="isPlaying" (click)="pauseVideo()"></div>
                                <div class="overlay" (click)="isPlaying ? pauseVideo() : playVideo()"></div>
                                <div *ngIf="videoLoaded" class="d-flex justify-content-center align-items-center"
                                    style="height: 100vh;">
                                    <div class="spinner-border text-success" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <ul class="info">
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-teacher"></i> المعلم</span>
                                    {{course?.teacher?.name}}
                                </div>
                            </li>

                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><i class="flaticon-distance-learning"></i> عدد الدروس</span>
                                    {{course?.videosLength.length}}
                                </div>
                            </li>



                        </ul>
                        <div class="btn-box">
                            <a routerLink="/payCard" class="default-btn"><i class="flaticon-tag"></i>

                                إشترك الآن
                                <span></span></a>
                        </div>
                        <div class="courses-share">
                            <div class="share-info">
                                <span>شارك هذه الدورة <i class="flaticon-share"></i></span>
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a>
                                    </li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="courses-area bg-f8f9f8 pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>المزيد من الدورات </h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-1" class="d-block image">
                                <img src="assets/img/courses/img1.jpg" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$39</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <span>Alex Morgan</span>
                            </div>
                            <h3><a routerLink="/single-courses-1">The Data Science Course 2024: Complete Data Science
                                    Bootcamp</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut
                                labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                <li><i class='flaticon-people'></i> 145 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-1" class="d-block image">
                                <img src="assets/img/courses/img2.jpg" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$49</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                            <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software
                                    Developers</a>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut
                                labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                <li><i class='flaticon-people'></i> 100 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-1" class="d-block image">
                                <img src="assets/img/courses/img3.jpg" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$59</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                <span>David Warner</span>
                            </div>
                            <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural
                                    Networks</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut
                                labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                <li><i class='flaticon-people'></i> 150 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>