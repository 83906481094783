import { Component, OnInit } from '@angular/core'
import lgVideo from 'lightgallery/plugins/video'
import { BeforeSlideDetail } from 'lightgallery/lg-events'
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-courses-details-style-one',
    templateUrl: './courses-details-style-one.component.html',
    styleUrls: ['./courses-details-style-one.component.scss']
})
export class CoursesDetailsStyleOneComponent implements OnInit {
    loading: boolean = true
    course: any = {}
    isPlaying: boolean = false
    prvVideo: string = ''
    videoLoaded: boolean = false
    constructor (private http: HttpClient, private router: Router, private title: Title, private route: ActivatedRoute) {
        const url = this.router.url
        const id = url.split('/').pop()

        const token: string = new Date().getTime().toString()
        const API_URL = environment.API_URL
        this.http
            .get(
                `${API_URL}/api/v1/contentapis?type=getCourseDetailsById&id=${id}`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => {
                // if res object is empty, redirect to home page
                if (Object.keys(res).length === 0) {
                    this.router.navigate(['/'])
                }
                this.course = res
                this.title.setTitle(
                    this.course.name + ' | ' + environment.siteName
                )
                this.loading = false
            })

        //https://cms.hemamacademy.com/api/v1/contentapis?type=getVidewStream&id=11&videoTocken=Mjc6MTcxNDY4NTQyOQ==
        //convert base64 to string
        this.videoLoaded = true
        this.http
            .get(
                `https://cms.hemamacademy.com/api/v1/contentapis?type=getVidewStream&id=11&videoTocken=Mjc6MTcxNDY4NTQyOQ==`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => { 
                let binaryData = res.binaryData.replace(/AA==/g, 'AA')
                //replace all EEEEEEE with empty string
                binaryData = binaryData.replace(/EEEEEEE/g, '')
                //convert base64 to blob
                const blob = this.b64toBlob(binaryData, 'video/mp4')
                //convert blob to url
                const url = URL.createObjectURL(blob)
                //set url to video src
                const video = document.getElementById(
                    'video'
                ) as HTMLVideoElement
                video.src = url
                this.prvVideo = url
                this.videoLoaded = false

                //don't allow to save video
                video.addEventListener('contextmenu', function (e) {
                    e.preventDefault()
                })
            }) 
    }

    ngOnInit (): void {}
    b64toBlob (b64Data: string, contentType: string, sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data)
        const byteArrays = []
        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)
            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }
        return new Blob(byteArrays, { type: contentType })
    }
    settings = {
        counter: false,
        plugins: [lgVideo]
    }
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail
        console.log(index, prevIndex)
    }

    // Tabs
    currentTab = 'tab1'
    switchTab (event: MouseEvent, tab: string) {
        event.preventDefault()
        this.currentTab = tab
    }

    playVideo () {
        const video = document.getElementById('video') as HTMLVideoElement
        video.play()
        this.isPlaying = true
    }
    pauseVideo () {
        const video = document.getElementById('video') as HTMLVideoElement
        video.pause()
        this.isPlaying = false
    }
}
