<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Success Story</li>
            </ul>
            <h2>Success Story</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="success-story-area pb-100">
    <div class="container">
        <div class="video-box mt-0">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                    data-sub-html="<p>Watch Video</p>"
                    class="d-block"
                >
                    <div class="image">
                        <img src="assets/img/success-story.jpg" class="shadow" alt="image">
                    </div>
                    <div class="video-btn popup-youtube">
                        <i class="flaticon-play"></i>
                    </div>
                </a>
            </lightgallery>
            <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="testimonials-area ptb-100 bg-fef8ef">
    <div class="container">
        <div class="single-testimonials-box">
            <img src="assets/img/user1.jpg" class="client-img" alt="image">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
            <h3>John Smith</h3>
            <span>Python Developer</span>
            <div class="shape-img">
                <img src="assets/img/shape4.png" class="shape-1" alt="image">
                <img src="assets/img/shape14.png" class="shape-2" alt="image">
                <img src="assets/img/shape7.png" class="shape-3" alt="image">
            </div>
        </div>
    </div>
</div>

<app-our-story></app-our-story>

<app-success-people></app-success-people>