import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-features-style-one',
    templateUrl: './features-style-one.component.html',
    styleUrls: ['./features-style-one.component.scss']
})
export class FeaturesStyleOneComponent implements OnInit {
    apiURL = environment.API_URL
    content: any
    parts: any
    constructor (private http: HttpClient) {
        const API_URL = environment.API_URL + '/api/v1/pages/why'
        this.http.get(API_URL).subscribe((data: any) => {
            if (data.length === 0) {
                return
            }
            this.content = data[0]
            this.parts = this.content.field_parts_export
            this.apiURL = environment.API_URL
        })
    }

    ngOnInit (): void {}
}
