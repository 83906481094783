<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="sr-only"></span>
    </div>
</div>

<div *ngIf="!loading">
    <app-elearning-banner></app-elearning-banner>

    <div class="features-area pt-100 pb-70">
        <app-features-style-one></app-features-style-one>
    </div>


    <div id="classes">
        <app-top-categories></app-top-categories>
    </div>
 

    <app-funfacts-feedback></app-funfacts-feedback>

    <div class="blog-area ptb-100">
        <app-blog></app-blog>
    </div>

    <div class="subscribe-area bg-f9f9f9 ptb-100">
        <div class="container">
            <app-subscribe></app-subscribe>
        </div>
        <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
        <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
        <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
        <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
    </div>
</div>