<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="features-area pt-100 pb-70">
    <app-features-style-two></app-features-style-two>
</div>

<div class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/" class="link-btn">Explore Learning</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a
                            data-lg-size="1280-720"
                            data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                            data-sub-html="<p>Watch Video</p>"
                            class="d-block"
                        >
                            <div class="image">
                                <img src="assets/img/about-img5.jpg" alt="image">
                            </div>
                            <div class="video-btn">
                                <i class="flaticon-play"></i>
                            </div>
                        </a>
                    </lightgallery>
                    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
</div>

<app-feedback></app-feedback>

<div class="bg-f9f9f9 pb-100">
    <app-instructor></app-instructor>
</div>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<div class="premium-access-area ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>