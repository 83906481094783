import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-about-style-one',
    templateUrl: './about-style-one.component.html',
    styleUrls: ['./about-style-one.component.scss']
})
export class AboutStyleOneComponent implements OnInit {
    pageContent: any = {}
    siteName: string = ''
    loading: boolean = true
    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private http: HttpClient,
        private router: Router
    ) {
        this.siteName = environment.siteName
        //http://localhost:55772/about
        //get about frq
         let pageID = this.route.snapshot.routeConfig.path
         const apiUrl = environment.API_URL + '/api/v1/pages/' + pageID
        this.http.get(apiUrl).subscribe((data: any) => {
            if (data.length === 0) {
                return
            }
            this.pageContent = data[0]
            this.title.setTitle(
                this.pageContent['title'] + ' | ' + environment.siteName
            )
            this.loading = false
        })
    }
    ngOnInit (): void {}
}
