<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>{{teacherData?.name}}</li>
            </ul>
            <h2>{{teacherData?.name}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-100 pb-70">
    <div class="container">
        <div class="profile-box">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="image">
                        <img [src]="teacherData?.photo" alt="image" />
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="content">
                        <h3>{{teacherData?.name}}</h3>
                        <span class="sub-title">{{teacherData?.details}}</span>

                        <button class="btn btn-secondary btn-sm">
                            متابعة
                            <i class='bx bx-plus'></i>
                        </button>
                        &nbsp;
                        <button class="btn btn-secondary btn-sm ">
                             إضافة إلى المفضلة
                            <i class='bx bx-heart'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-courses-quizzes">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        الدورات
                    </span>
                </li>

            </ul>
            <div class="tabs-container">

                <div class="pane" id="tab2" *ngIf="currentTab === 'tab1'">

                    <div class="tabs-container">
                        <div class="pane" id="tab-pane1" *ngIf="currentTab2 === 'tab-pane1'">

                            <div class="tabs-container">
                                <div class="pane" id="tab-pane-pane1" *ngIf="currentTab3 === 'tab-pane-pane1'">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img1.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user1.jpg" class="rounded-circle"
                                                            alt="image">
                                                        <span>Alex Morgan</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">The Data Science Course 2024:
                                                            Complete Data Science Bootcamp</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img2.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user2.jpg" class="rounded-circle"
                                                            alt="image">
                                                        <span>Sarah Taylor</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass
                                                            for Software Developers</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img3.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <div class="course-author d-flex align-items-center">
                                                        <img src="assets/img/user3.jpg" class="rounded-circle"
                                                            alt="image">
                                                        <span>David Warner</span>
                                                    </div>
                                                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On
                                                            Artificial Neural Networks</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img6.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$39</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Machine Learning
                                                            Practical:Real world Projects in Healthcare</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img7.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$49</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Teach Your Kids to Code: Learn
                                                            Python Programming at Any Age</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="single-courses-box">
                                                <div class="courses-image">
                                                    <a routerLink="/single-courses-1" class="d-block image">
                                                        <img src="assets/img/courses/img8.jpg" alt="image">
                                                    </a>
                                                    <a routerLink="/single-courses-1" class="fav"><i
                                                            class="flaticon-heart"></i></a>
                                                    <div class="price shadow">$59</div>
                                                </div>
                                                <div class="courses-content">
                                                    <h3><a routerLink="/single-courses-1">Data Science 2024 : Complete
                                                            Data Science & Machine Learning</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore.</p>
                                                    <ul
                                                        class="courses-box-footer d-flex justify-content-between align-items-center">
                                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                                        <li><i class='flaticon-people'></i> 150 Students</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-12 col-md-12">
                                            <div class="pagination-area text-center">
                                                <a routerLink="/profile" class="prev page-numbers"><i
                                                        class='bx bx-chevrons-left'></i></a>
                                                <span class="page-numbers current" aria-current="page">1</span>
                                                <a routerLink="/profile" class="page-numbers">2</a>
                                                <a routerLink="/profile" class="page-numbers">3</a>
                                                <a routerLink="/profile" class="page-numbers">4</a>
                                                <a routerLink="/profile" class="page-numbers">5</a>
                                                <a routerLink="/profile" class="next page-numbers"><i
                                                        class='bx bx-chevrons-right'></i></a>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>