<div class="navbar-area" [ngClass]="{'sticky': isSticky}">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-.png" alt="logo"></a>

            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <form class="search-box">
                    <input type="text" class="input-search" placeholder="ابحث عن دورة" (keyup)="searchCourse($event)"
                        [value]="keyword" />
                    <div [class]="'searchBox ' + (showSearch ? ' show' : 'hide')">
                        <ul *ngIf="courses?.length > 0">
                            <li *ngFor="let course of courses" (click)="getToCourse(course.id)">
                                <img [src]="course?.photo" [alt]="course?.name" [title]="course?.name"
                                    class="img-fluid">
                                <a href="javascript:void(0)">{{course?.name}}</a>
                            </li>
                        </ul>
                        <p *ngIf="courses?.length == 0">لا يوجد نتائج</p>
                    </div>
                    <button type="button"><i class="flaticon-search"></i></button>
                </form>
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/about" class="nav-link">من نحن </a> </li>
                    <li class="nav-item"><a routerLink="/services" class="nav-link">خدماتنا </a> </li>
                    <li class="nav-item"><a routerLink="/payCard" class="nav-link">نقاط بيع بطاقات هِمَم </a> </li>
                    <li class="nav-item"><a routerLink="/blogs" class="nav-link">اخبارنا </a> </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link">تواصل معنا </a> </li>

                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/about" class="nav-link">من نحن </a> </li>
                    <li class="nav-item"><a routerLink="/services" class="nav-link">خدماتنا </a> </li>
                    <li class="nav-item"><a routerLink="/payCard" class="nav-link">نقاط بيع بطاقات هِمَم </a> </li>
                    <li class="nav-item"><a routerLink="/blogs" class="nav-link">اخبارنا </a> </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link">تواصل معنا </a> </li>

                </ul>
            </div>
            <div class="others-option d-flex align-items-center" *ngIf="!loading">

                <div class="option-item" *ngIf="!isLogin">
                    <a routerLink="/authentication" class="default-btn font-14-px"><i class="flaticon-user"></i>

                        <div class="d-none d-lg-block">تسجيل الدخول/تسجيل</div>

                        <span></span>
                    </a>
                </div>
                <!-- ul list of options for logged in users -->

                <div *ngIf="isLogin" class="logedinNav">
                    <Button class="btn btn-primary btn-sm bg-btn" (click)="openMenuMethod()">
                        <div class="d-none d-lg-block"><i class="flaticon-user"></i> &spar; مرحبا {{userData?.name}} </div>
                        <div class="d-lg-none"><i class="flaticon-user"></i></div>
                        
                    </Button>
                    <div class="dropdown-custom" [ngClass]="{'show': openMenu}">
                        <div class="custom-item">
                            <a routerLink="/profile" (click)="openMenuMethod()" class="nav-link">الصفحة الشخصية</a>
                        </div>
                        <div class="custom-item">
                            <a (click)="logout()" class="nav-link cursor-pointer">تسجيل الخروج</a>
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    </div>
    <div class="overlay" [ngClass]="{ 'show': overlay}" (click)="openMenuMethod()"></div>
</div>