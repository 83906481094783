<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>معلمين هِمَم</li>
            </ul>
            <h2>معلمين هِمَم</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="advisor-area pt-100 pb-70" *ngIf="teachers.length > 0">
    <div class="container">
        <!-- map in teacher array -->
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let teacher of teachers">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img [src]="teacher.photo" [alt]="teacher.name" />
                        <!-- <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul> -->
                    </div>
                    <div class="advisor-content">
                        <h3><a [routerLink]="'/teacher/'+teacher.id"> {{teacher.name}}</a></h3>
                        <span>{{teacher.field_details}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

<div class="advisor-area pt-100 pb-70" *ngIf="teachers.length == 0 && !loading">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-advisor-item">
                    <div class="advisor-content">
                        <h3>لا يوجد معلمين حاليا</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="advisor-area pt-100 pb-70" *ngIf="loading">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-advisor-item">
                    <div class="advisor-content">
                        <h3>جاري التحميل ...</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>