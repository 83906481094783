import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment.prod'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-profile-authentication',
    templateUrl: './profile-authentication.component.html',
    styleUrls: ['./profile-authentication.component.scss']
})
export class ProfileAuthenticationComponent {
    userForm: any
    userFormLogin: any
    errors: Array<any> = []
    errorsLogin: Array<any> = []
    sucMsgs: Array<any> = []
    cardNumber: string = ''

    getCookie (name: string) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    constructor (
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private title: Title,
        private route: ActivatedRoute
    ) {
        this.title.setTitle(route.snapshot.data.title)
        const token = document.cookie ? this.getCookie('token') : ''
        if (token) {
            window.location.href = '/'
            return
        }
    }
    ngOnInit (): void {
        this.userForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: [
                '',
                [Validators.required, Validators.pattern('[0-9]{9,10}')]
            ],
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20)
                ]
            ],
            cardNumber: [
                '',
                [Validators.required, Validators.pattern('[0-9]{14}')]
            ]
        })
        this.userFormLogin = this.formBuilder.group({
            emailLogin: ['', [Validators.required, Validators.email]],
            passwordLogin: ['', [Validators.required]]
        })
    }
    //if this.userForm.value.cardNumber is change
    onCardNumberChange (): void {
        //remove all errors
        this.errors = []
        this.cardNumber = this.userForm.value.cardNumber
        if (this.cardNumber.length === 14) {
            this.userForm.get('cardNumber').disable()
            const API_URL = environment.API_URL
            this.http
                .post(API_URL + '/api/v1/CheckCard', {
                    number: this.cardNumber
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.sucMsgs.push({
                            key: 'cardNumber',
                            message: response.message
                        })
                    } else {
                        //Enable card number field
                        this.userForm.get('cardNumber').enable()
                        //Show error message
                        this.errors.push({
                            key: 'cardNumber',
                            message: response.message
                        })
                    }
                })
        }
    }

    submitForm (): void {
        this.errors = this.errors.filter(error => error.key !== 'submitForm')
        if (this.userForm?.valid && this.errors.length === 0) {
            const API_URL = environment.API_URL
            this.http
                .post(API_URL + '/api/v1/loginRegister', {
                    type: 'register',
                    email: this.userForm.value.email.toLowerCase().trim(),
                    fullname: this.userForm.value.name,
                    password: this.userForm.value.password,
                    card: this.cardNumber,
                    phone: this.userForm.value.mobile,
                    bod: ''
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.sucMsgs.push({
                            key: 'submitForm',
                            message: response.message
                        })
                        // set token in cookie with 1 day expiry
                        const date = new Date()
                        date.setTime(date.getTime() + 24 * 60 * 60 * 1000)
                        document.cookie = `token=${
                            response.token
                        }; expires=${date.toUTCString()}; path=/`

                        //redirect to home page
                        window.location.href = '/'
                    } else {
                        this.errors.push({
                            key: 'submitForm',
                            message: response.message
                        })
                    }
                })
        }
    }

    login (): void {
        this.errorsLogin = this.errorsLogin.filter(error => error.key !== 'login')
        // if (this.userForm?.valid && this.errors.length === 0) {
        const API_URL = environment.API_URL
        this.http
            .post(API_URL + '/api/v1/loginRegister', {
                type: 'login',
                email: this.userFormLogin.value.emailLogin.toLowerCase().trim(),
                password: this.userFormLogin.value.passwordLogin
            })
            .subscribe((response: any) => {
                if (response.status) {
                    this.sucMsgs.push({
                        key: 'login',
                        message: response.message
                    })
                    // set token in cookie with 1 day expiry
                    const date = new Date()
                    date.setTime(date.getTime() + 24 * 60 * 60 * 1000)
                    document.cookie = `token=${
                        response.token
                    }; expires=${date.toUTCString()}; path=/`

                    //redirect to home page useing routerLink
                    // this.router.navigate(['/'])
                    //redirect to home page
                    window.location.href = '/'
                } else {
                    this.errorsLogin.push({
                        key: 'login',
                        message: response.message
                    })
                }
            })
        // }
    }
}
