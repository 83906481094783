<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="logo" width="150"></a>
                    <p>منصة "هِمَم التعليمية" هي منصة تعليمية عربية تهدف إلى تقديم محتوى تعليمي متنوع وجودة عالية للمتعلمين من جميع الأعمار والمستويات التعليمية. تتضمن المنصة مقاطع فيديو تعليمية، ودروس مباشرة، ومواد تفاعلية، وموارد تعليمية متنوعة في مجالات مثل العلوم، الرياضيات، اللغات، الفنون، والتكنولوجيا.

                    </p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5"> 
                    <ul class="footer-links-list">
                        <li><a routerLink="/">الرئيسية</a></li>
                        <li><a routerLink="/about">من نحن</a></li>
                        <li><a routerLink="/courses-1">خدماتنا</a></li>
                        <li><a routerLink="/events">الدورات</a></li>
                        <li><a routerLink="/contact">تواصل معنا</a></li>
                    </ul>
                </div>
            </div>
         
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget"> 
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>عمان، الأردن</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">962790171950+</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info&#64;hemamacademy.com">info&#64;hemamacademy.com</a></li>
                     </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© منصة هِمَم التعليمية  {{currentYear}}.</p> 
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">سياسة الخصوصية</a></li>
                        <li><a routerLink="/terms-of-service">شروط الخدمة</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>