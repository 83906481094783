<div class="kitchen-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="kitchen-about-content">
                    <span class="sub-title">About Me</span>
                    <h2 class="font-weight-black">The most key element of all great home cooking: love for those you are cooking for.</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Instruction</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Access</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/contact" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="kitchen-about-image">
                    <img src="assets/img/kitchen-home/kitchen-about.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>