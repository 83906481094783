import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    classes: any = {}
    subjects: any = []
    loading: boolean = true
    constructor (
        private http: HttpClient,
        private router: Router,
        private title: Title,
        private route: ActivatedRoute
    ) {
        // get id from url
        const url = this.router.url
        const id = url.split('/').pop()

        const token: string = new Date().getTime().toString()
        const API_URL = environment.API_URL
        this.http
            .get(`${API_URL}/api/v1/contentapis?type=getClassById&id=${id}`, {
                headers: {
                    'x-auth-token': token
                }
            })
            .subscribe((res: any) => {
                if (res.length === 0) {
                    this.router.navigate(['/'])
                }
                this.classes = res[0]
                this.title.setTitle(
                    'المواد الدراسية ' +
                        this.classes.name +
                        ' | ' +
                        environment.siteName
                )
            })

        this.http
            .get(
                `${API_URL}/api/v1/contentapis?type=getSubjectsById&id=${id}`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => {
                this.subjects = res
                this.loading = false
            })
    }

    ngOnInit (): void {}
}
