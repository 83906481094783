import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment.prod'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    userData: any = {}
    userFormResetPassword: any = {}
    loading: boolean = true
    loadingPhoto: boolean = false
    errors: Array<any> = []
    sucMsgs: Array<any> = []
    selectedDate: Date
    userForm: any = {}
    maxDate: Date = new Date()
    expPopup: boolean = false
    renewForm: any = {}
    getCookie (name: string) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    getUserDate () {
        const API_URL = environment.API_URL
        const token = document.cookie ? this.getCookie('token') : ''
        this.http
            .post(API_URL + '/api/v1/loginRegister', {
                type: 'getUserData',
                token: token
            })
            .subscribe((response: any) => {
                if (response.status) {
                    this.userData = response
                    response.last = parseInt(response.last) * 1000
                    this.userData.last = new Date(
                        response.last
                    ).toLocaleString()
                    this.userForm = this.formBuilder.group({
                        fullName: [response.fullname, Validators.required],
                        phone: [response.phone, Validators.required],
                        dob: [response.bod, Validators.required]
                    })
                    // if response.exp "2024-10-30" is less than current date
                    // show expPopup
                    if (
                        response.role === 'student' &&
                        new Date(response.exp) < new Date()
                    ) {
                        this.expPopup = true
                    }
                    this.loading = false
                } else {
                    window.location.href = '/authentication'
                }
            })
    }

    constructor (
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private router: Router,
        private title: Title,
        private route: ActivatedRoute
    ) {
        this.title.setTitle('الملف الشخصي' + ' | ' + environment.siteName)
        const token = document.cookie ? this.getCookie('token') : ''
        if (!token) {
            window.location.href = '/authentication'
            return
        }
        this.getUserDate()
    }

    ngOnInit (): void {
        this.userFormResetPassword = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20)
                ]
            ],
            confirmPassword: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20)
                ]
            ]
        })
        this.renewForm = this.formBuilder.group({
            cardNumber: [
                '',
                [Validators.required, Validators.pattern('[0-9]{14}')]
            ]
        })
    }

    // Tabs
    currentTab = 'tab1'
    currentTab2 = 'tab-pane1'
    currentTab3 = 'tab-pane-pane1'
    switchTab (event: MouseEvent, tab: string) {
        event.preventDefault()
        this.currentTab = tab
    }
    switchTab2 (event: MouseEvent, tabPane: string) {
        event.preventDefault()
        this.currentTab2 = tabPane
    }
    switchTab3 (event: MouseEvent, tabPane1: string) {
        event.preventDefault()
        this.currentTab3 = tabPane1
    }
    changePhoto (): void {
        //create file browser
        const fileBrowser = document.createElement('input')
        fileBrowser.setAttribute('type', 'file')
        fileBrowser.setAttribute('accept', 'image/*')
        fileBrowser.click()

        //add event listener
        fileBrowser.addEventListener('change', () => {
            this.loadingPhoto = true
            const file = fileBrowser.files[0]
            const token = document.cookie ? this.getCookie('token') : ''

            const reader = new FileReader()
            reader.onload = () => {
                const API_URL = environment.API_URL
                let data = new FormData()
                data.append('token', token)
                data.append('photo', file)
                this.http
                    .post(API_URL + '/api/v1/changePhoto', data)
                    .subscribe((response: any) => {
                        if (response.status) {
                            this.userData.photo = response.photo_url
                            this.loadingPhoto = false
                        }
                    })
            }
            reader.readAsDataURL(file)
        })
    }

    resetpassword () {
        this.errors = []
        if (
            this.userFormResetPassword.value.newPassword !==
            this.userFormResetPassword.value.confirmPassword
        ) {
            this.errors.push({
                key: 'resetpassword',
                message: 'كلمة المرور غير متطابقة'
            })
            return
        }
        const token = document.cookie ? this.getCookie('token') : ''

        this.errors = this.errors.filter(error => error.key !== 'resetpassword')
        if (this.userFormResetPassword?.valid && this.errors.length === 0) {
            const API_URL = environment.API_URL
            this.http
                .post(API_URL + '/api/v1/loginRegister', {
                    token: token,
                    type: 'resetPassword',
                    oldPassword: this.userFormResetPassword.value.oldPassword,
                    password: this.userFormResetPassword.value.newPassword
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.sucMsgs.push({
                            key: 'resetpassword',
                            message: response.message
                        })
                    } else {
                        this.errors.push({
                            key: 'resetpassword',
                            message: response.message
                        })
                    }
                })
        }
    }
    updateProfile () {
        const token = document.cookie ? this.getCookie('token') : ''
        this.errors = []
        this.sucMsgs = []
        this.errors = this.errors.filter(error => error.key !== 'updateProfile')
        if (this.userForm?.valid && this.errors.length === 0) {
            const API_URL = environment.API_URL
            let bod: any = new Date(this.userForm.value.dob)
            //convert to YYYY-MM-DD
            bod = new Date(bod.getTime() - bod.getTimezoneOffset() * 60000)

            bod = bod.toISOString().split('T')[0]

            this.http
                .post(API_URL + '/api/v1/loginRegister', {
                    token: token,
                    type: 'updateProfile',
                    fullname: this.userForm.value.fullName,
                    phone: this.userForm.value.phone,
                    bod: bod
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.sucMsgs.push({
                            key: 'updateProfile',
                            message: response.message
                        })
                    } else {
                        this.errors.push({
                            key: 'updateProfile',
                            message: response.message
                        })
                    }

                    //emptys sucMsgs after 5 seconds
                    setTimeout(() => {
                        this.sucMsgs = []
                    }, 5000)

                    //emptys errors after 5 seconds
                    setTimeout(() => {
                        this.errors = []
                    }, 5000)
                })
        }
    }

    getRoleName (role: string) {
        switch (role) {
            case 'student':
                return 'الطالب'
            case 'teacher':
                return 'المعلم'
            case 'administrator':
                return 'المدير'
            default:
                return ''
        }
    }
    renewSubscription () {
        const token = document.cookie ? this.getCookie('token') : ''
        this.errors = []
        this.sucMsgs = []
        this.errors = this.errors.filter(
            error => error.key !== 'renewSubscription'
        )
        if (this.renewForm?.valid && this.errors.length === 0) {
            const API_URL = environment.API_URL
            this.http
                .post(API_URL + '/api/v1/loginRegister', {
                    token: token,
                    type: 'renewSubscription',
                    card: this.renewForm.value.cardNumber
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.sucMsgs.push({
                            key: 'renewSubscription',
                            message: response.message
                        })
                        this.getUserDate()
                    } else {
                        this.errors.push({
                            key: 'renewSubscription',
                            message: response.message
                        })
                    }
                })
        }
    }
    closeExpPopup () {
        this.expPopup = false
    }
    payCard () {
        //go to payCard page
        this.router.navigate(['/payCard'])
    }
}
