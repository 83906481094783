<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="sr-only"></span>
    </div>
</div>
<div *ngIf="!loading">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية </a></li>
                    <li>المواد الدراسية</li>
                    <li><a [routerLink]="'/class/'+ classId">{{classes?.name}}</a></li>
                </ul>
                <h2> {{subjectName}}</h2>

            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="courses-area ptb-100">
        <div class="container">
            <div class="odemy-grid-sorting row align-items-center">
                <div class="col-lg-6 col-md-6 result-count">
                    <p> لديك
                        <span class="count">{{
                            courses?.length
                            }}</span> دورة تدريبية
                    </p>
                </div>
                <div class="col-lg-6 col-md-6 ordering">
                    <div class="select-box">
                        <label>ترتيب حسب</label>
                        <select>
                            <option value="relevance">الأكثر ملائمة</option>
                            <option value="popular">الأكثر شهرة</option>
                            <option value="latest">الأحدث</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- map in courses array -->
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let course of courses">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a [routerLink]="'/course/'+course.id" class="d-block image">
                                <img [src]="course.photo" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <a [routerLink]="'/teacher/'+course.teacher.id">
                                    <img [src]="course.teacher.photo" class="rounded-circle" alt="image">
                                    <span>{{course.teacher.name}}</span>
                                </a>
                            </div>
                            <h3><a [routerLink]="'/course/'+course.id">{{course.name}}</a></h3>
                            <p>{{removeTags(course.description)}}</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> {{course.videosLength}} دروس</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>