<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">الرئيسية</a></li>
                <li>التسجيل والدخول</li>
            </ul>
            <h2>مرحبا بكم في منصة هِمَم التعليمية</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>تسجيل الدخول</h2>
                    <form (ngSubmit)="login()" [formGroup]="userFormLogin">
                        <div class="form-group" *if="sucMsgs.key === 'login'">
                            <div *ngFor="let sucMsg of sucMsgs" class="alert alert-success mt-3">
                                <span *ngIf="sucMsg.key === 'login'">{{sucMsg.message}}</span>
                            </div>
                        </div>
                        <div *ngFor="let error of errorsLogin" class="alert alert-danger mt-3">
                            <span *ngIf="error.key === 'login'">{{error.message}}</span>
                        </div>
                        <div class="form-group">
                            <label>البريد الإلكتروني او رقم الهاتف</label>
                            <input type="text" class="form-control" placeholder="البريد الإلكتروني او رقم الهاتف"
                                formControlName="emailLogin">
                        </div>
                        <div class="form-group">
                            <label>كلمة المرور</label>
                            <input type="password" class="form-control" placeholder="كلمة المرور"
                                formControlName="passwordLogin">
                        </div> 
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <!-- Add remember me functionality here if needed -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a href="/" class="lost-your-password">هل نسيت كلمة المرور</a>
                            </div>
                        </div>
                        <button type="submit">دخول</button>

                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>إنشاء حساب</h2>
                    <form (ngSubmit)="submitForm()" [formGroup]="userForm">
                        <div class="form-group">
                            <label>رقم البطاقة</label>
                            <input type="text" class="form-control" placeholder="رقم البطاقة"
                                formControlName="cardNumber" (input)="onCardNumberChange()">

                            <div *ngIf="userForm?.get('cardNumber')?.invalid && userForm?.get('cardNumber')?.touched"
                                class="text-danger">
                                رقم البطاقة غير صحيح
                            </div>
                            <!-- errors[{key: "cardNumber", message:"...."}] display here if array includes cardNumber -->
                            <div *ngFor="let error of errors" class="text-danger">
                                <span *ngIf="error.key === 'cardNumber'">{{error.message}}</span>
                            </div>
                            <!-- sucMsgs[{key: "cardNumber", message:"...."}] display here if array includes cardNumber -->
                            <div *ngFor="let sucMsg of sucMsgs" class="text-success">
                                <span *ngIf="sucMsg.key === 'cardNumber'">{{sucMsg.message}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>الاسم الثلاثي</label>
                            <input type="text" class="form-control" placeholder="الاسم الثلاثي" formControlName="name">
                            <div *ngIf="userForm?.get('name')?.invalid && userForm?.get('name')?.touched"
                                class="text-danger">
                                الاسم الثلاثي غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label>البريد الإلكتروني</label>
                            <input type="email" formControlName="email" class="form-control"
                                placeholder="البريد الإلكتروني">
                            <div *ngIf="userForm?.get('email')?.invalid && userForm?.get('email')?.touched"
                                class="text-danger">
                                البريد الإلكتروني غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label> رقم الهاتف</label>
                            <div class="input-group">
                                <input type="email" formControlName="mobile" class="form-control"
                                    placeholder="  رقم الهاتف">
                                <div class="phoneCode">
                                    🇯🇴 +962
                                </div>
                            </div>
                            <div *ngIf="userForm?.get('mobile')?.invalid && userForm?.get('mobile')?.touched"
                                class="text-danger">
                                رقم الهاتف غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label>كلمة المرور</label>
                            <input type="password" class="form-control" placeholder="كلمة المرور"
                                formControlName="password">
                            <div *ngIf="userForm?.get('password')?.invalid && userForm?.get('password')?.touched"
                                class="text-danger">
                                كلمة المرور غير صحيحة
                            </div>
                        </div>
                        <p class="description">يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل. ولجعلها أقوى، استخدم
                            الأحرف الكبيرة والصغيرة والأرقام والرموز مثل ! " ؟ $ % ^ & )</p>
                        <button type="submit"
                            [disabled]="userForm.invalid || userForm.pristine || userForm.pending">إنشاء حساب</button>

                        <!-- errors[{key: "submitForm", message:"...."}] display here if array includes submitForm -->
                        <div *ngFor="let error of errors" class="alert alert-danger mt-3">
                            <span *ngIf="error.key === 'submitForm'">{{error.message}}</span>
                        </div>

                        <!-- sucMsgs[{key: "submitForm", message:"...."}] display here if array includes submitForm -->
                        <div *ngFor="let sucMsg of sucMsgs">
                            <div class="alert alert-success mt-3" *ngIf="sucMsg.key === 'submitForm'">
                                <span>{{sucMsg.message}}</span>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>