<div class="container">
    <div class="premium-access-content">
        <span class="sub-title">النشرة الإخبارية</span>
        <h2>اشترك لمعرفة آخر الأخبار </h2>
        <div class="form-group w-50 m-auto mt-3">
            <input type="text" class="form-control" placeholder="البريد الإلكتروني"
                formControlName="emailLogin">
        </div>
        <a routerLink="/" class="default-btn">
             إشترك
            <i class="flaticon-right"></i>
            <span></span></a>
    </div>
</div>