import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss']
})
export class ElearningSchoolComponent implements OnInit {
    //print data from route
    loading: boolean = true
    constructor (private route: ActivatedRoute, private title: Title) {
        this.title.setTitle(route.snapshot.data.title)
        setTimeout(() => {
            this.loading = false
        }, 1000)
    }

    ngOnInit (): void {}
}
