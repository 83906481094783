import { Component, OnInit } from '@angular/core'
import { OwlOptions } from 'ngx-owl-carousel-o'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-funfacts-feedback',
    templateUrl: './funfacts-feedback.component.html',
    styleUrls: ['./funfacts-feedback.component.scss']
})
export class FunfactsFeedbackComponent implements OnInit {
    successStories: any = []
    numbers: any = []
    pathServer = environment.API_URL

    constructor (public router: Router, private http: HttpClient) {
        //https://cms.hemamacademy.com/ar/api/v1/success-stories
        const API_URL = environment.API_URL
        this.http
            .get(API_URL + '/api/v1/success-stories')
            .subscribe((response: any) => {
                this.successStories = response
            })

        //https://cms.hemamacademy.com/ar/api/v1/numbers_home_page
        this.http
            .get(API_URL + '/api/v1/numbers_home_page')
            .subscribe((response: any) => {
                this.numbers = response
            })
    }

    ngOnInit (): void {}

    bgImage = [
        {
            img: 'assets/img/white-bg.jpg'
        }
    ]

    feedbackSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        rtl: true,
        autoplayHoverPause: true,
        autoplay: true,
        animateOut: 'fadeOut',
        autoHeight: true,
        items: 1,
        navText: [
            "<i class='bx bx-chevron-right'></i>",
            "<i class='bx bx-chevron-left'></i>"
        ]
    }
    oaFeedbackSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        rtl: true,
        animateOut: 'fadeOut',
        items: 1,
        navText: [
            "<i class='bx bx-chevron-right'></i>",
            "<i class='bx bx-chevron-left'></i>"
        ]
    }
}
