<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!loading">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li><a routerLink="/blogs">الأخبار والمدونات</a></li>
                    <li>{{blog?.title}}</li>
                </ul>
                <h2>{{blog?.title}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <img [src]="blog?.images[0]" [alt]="blog?.title">
                        </div>
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li>
                                        <i class='bx bx-folder-open'></i>
                                        <span>النوع</span>
                                        <a routerLink="/blogs">{{blog?.type}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-group'></i>
                                        <span>عدد المشاهدات</span>
                                        <a>{{blog?.views}}</a>
                                    </li>
                                    <li>
                                        <i class='bx bx-calendar'></i>
                                        <span>وقت النشر</span>
                                        <a>{{blog?.created}}</a>
                                    </li>
                                </ul>
                            </div>
                            <p [innerHTML]="blog?.body" class="mt-3"></p>
                            <!-- map in images withou first image -->
                            <div class="article-image mt-3">
                                <img *ngFor="let image of blog?.images.slice(1)" [src]="image" [alt]="blog?.title">
                            </div>
                        </div>
                        <div class="article-footer">

                            <div class="article-share">
                                <ul class="social">
                                    <li><span>مشاركة:</span></li>
                                    <li><a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + url"
                                            class="whatsapp" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li><a [href]="'https://www.linkedin.com/shareArticle?mini=true&url=' + url"
                                            class="whatsapp" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                    </li>
                                    <li><a [href]="'https://wa.me/?text=' + url" class="whatsapp" target="_blank"><i
                                                class='bx bxl-whatsapp'></i></a></li>
                                    <li><a [href]="'https://www.t.me/share/url?url=' + url" class="telegram"
                                            target="_blank"><i class='bx bxl-telegram'></i></a></li>

                                </ul>
                            </div>
                        </div>

                        <div class="odemy-post-navigation">
                            <div class="prev-link-wrapper">
                                <div class="info-prev-link-wrapper">
                                    <a [routerLink]="'/single-blog/'+ blog?.nextBlog?.id">
                                        <span class="image-prev">
                                            <img src="assets/img/blog/img12.jpg" alt="image">
                                            <span class="post-nav-title">السابق</span>
                                        </span>
                                        <span class="prev-link-info-wrapper">
                                            <span class="prev-title">{{blog?.nextBlog?.title}}</span>
                                            <span class="meta-wrapper">
                                                <span class="date-post">{{blog?.nextBlog?.created}}</span>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="next-link-wrapper">
                                <div class="info-next-link-wrapper">
                                    <a [routerLink]="'/single-blog/'+ blog?.previousBlog?.id">
                                        <span class="next-link-info-wrapper">
                                            <span class="next-title">
                                                {{blog?.previousBlog?.title}}
                                            </span>
                                            <span class="meta-wrapper">
                                                <span class="date-post"> {{blog?.previousBlog?.created}}</span>
                                            </span>
                                        </span>
                                        <span class="image-next">
                                            <img src="assets/img/blog/img12.jpg" alt="image">
                                            <span class="post-nav-title"> التالي</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <!-- <div class="widget widget_search">
                            <h3 class="widget-title">إبحث</h3>
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">إبحث عن:</span>
                                    <input type="search" class="search-field" placeholder="بحث...">
                                </label>
                                <button type="submit"><i class="bx bx-search-alt"></i></button>
                            </form>
                        </div> -->
                        <div class="widget widget_odemy_posts_thumb">
                            <h3 class="widget-title">منشورات شائعة</h3>
                            <article class="item wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
                                *ngFor="let item of blog?.popularPosts">
                                <a [routerLink]="'/single-blog/'+ item?.id" class="thumb">
                                    <span class="fullimage cover" role="img"
                                        [style.backgroundImage]="'url(' + item?.image + ')'"></span>
                                </a>
                                <div class="info">
                                    <span> {{item?.created}}</span>
                                    <h4 class="title usmall"><a [routerLink]="'/single-blog/'+ item?.id">
                                            {{item?.title}}
                                        </a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>

                        </div>

                    </aside>
                </div>
            </div>
        </div>
    </div>
</div>