<div class="coaching-types-area pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/business-coach/business-coach6.jpg" alt="image">
                    <h3 class="inter-font">One-On-One Coaching</h3>
                    <p>Excepteur sint occaecat cupidatat non proident, unt in culpa qui officia deserunt mollit anim id est laborum. But I must explain to you how all this mistaken idea of denouncing pleasure and praising.</p>
                    <a routerLink="/courses-1" class="link-btn">Discover More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/business-coach/business-coach7.jpg" alt="image">
                    <h3 class="inter-font">Monthly Group Coacing</h3>
                    <p>Excepteur sint occaecat cupidatat non proident, unt in culpa qui officia deserunt mollit anim id est laborum. But I must explain to you how all this mistaken idea of denouncing pleasure and praising.</p>
                    <a routerLink="/courses-1" class="link-btn">Discover More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/business-coach/business-coach8.jpg" alt="image">
                    <h3 class="inter-font">Diy Online Learning</h3>
                    <p>Excepteur sint occaecat cupidatat non proident, unt in culpa qui officia deserunt mollit anim id est laborum. But I must explain to you how all this mistaken idea of denouncing pleasure and praising.</p>
                    <a routerLink="/courses-1" class="link-btn">Discover More</a>
                </div>
            </div>
        </div>
    </div>
</div>