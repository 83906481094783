<div class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">News</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <a routerLink="/single-blog-1" class="d-block">
                                <img src="assets/img/blog/img1.jpg" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/blog-4" class="category">Education</a>
                            <h3><a routerLink="/single-blog-1">University Admissions Could Face Emergency Controls</a></h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 30, 2024</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <a routerLink="/single-blog-1" class="d-block">
                                <img src="assets/img/blog/img2.jpg" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/blog-4" class="category">Online</a>
                            <h3><a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast</a></h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 29, 2024</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <a routerLink="/single-blog-1" class="d-block">
                                <img src="assets/img/blog/img3.jpg" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/blog-4" class="category">Learning</a>
                            <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 28, 2024</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <a routerLink="/single-blog-1" class="d-block">
                                <img src="assets/img/blog/img3.jpg" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/blog-4" class="category">Learning</a>
                            <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 28, 2024</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="blog-post-info">
            <p>Get into details now?​ <a routerLink="/blog-4">View all posts</a></p>
        </div>
    </div>
</div>