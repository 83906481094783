<div class="funfacts-and-feedback-area ptb-100" *ngFor="let Image of bgImage;"
    style="background-image: url({{Image.img}});" [ngClass]="{'d-none': router.url === '/online-art'}">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">طلابنا</span>
                    <h2>قصص نجاح طلابنا</h2>

                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide *ngFor="let feedback of successStories" id="feedback.nid">
                                <div class="single-feedback-item">
                                    <p [innerHTML]="feedback.body"></p>
                                    <div class="client-info d-flex align-items-center">
                                        <img [src]="pathServer+feedback.field_pic" class="rounded-circle w-30"
                                            alt="image">
                                        <div class="title">
                                            <h3>{{feedback.title}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </owl-carousel-o>
                    </div>

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let num of numbers">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="num.field_number">00</span>{{num.field_plus_text}}</h3>
                                <p>{{num.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

 