<div class="happiness-cooking-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="happiness-cooking-image">
                    <img src="assets/img/kitchen-home/happiness-cooking.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="happiness-cooking-content">
                    <span class="sub-title">Happiness is cooking together</span>
                    <h2 class="font-weight-black">The speed at which I can plan nourishment amid a business break is amazing..!!</h2>
                    <p>With The Open University you can study whenever and wherever you choose. We have students over 128 countries, and a global reputation as a pioneer in the field flexible learning. Our flexible teaching also means if you travel often need to relocate you can continue to study wherever you go.</p>
                    <div class="cooking-btn">
                        <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kitchen-shape-5">
        <img src="assets/img/kitchen-home/kitchen-shape-4.png" alt="image">
    </div>
    <div class="kitchen-shape-6">
        <img src="assets/img/kitchen-home/kitchen-shape-2.png" alt="image">
    </div>
    <div class="kitchen-shape-7">
        <img src="assets/img/kitchen-home/kitchen-shape-5.png" alt="image">
    </div>
</div>