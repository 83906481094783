<div class="container">
    <div class="section-title">
        <!-- <span class="sub-title">لماذا هِمَم</span> -->
        <h2> {{content?.title}}</h2>
    </div>
    <div class="row">
        <!-- map on content?. field_parts_export -->
        <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let i of content?.field_parts_export">
            <div class="single-features-box">
                <div class="icon">
                    <img [src]="apiURL + i.files[0]"
                     alt="" srcset="">
                </div>
                <h3>{{i.laanwn}}</h3>
                <p [innerHTML]="i.content"></p>
            </div>
        </div>
    </div>
</div>