import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-top-categories',
    templateUrl: './top-categories.component.html',
    styleUrls: ['./top-categories.component.scss']
})
export class TopCategoriesComponent implements OnInit {
    classes: any = []
    constructor (private http: HttpClient) {
        // https://cms.hemamacademy.com/api/v1/contentapis?type=getClass
        const token: string = new Date().getTime().toString()

        const API_URL = environment.API_URL
        this.http
            .get(`${API_URL}/api/v1/contentapis?type=getClass`, {
                headers: {
                    'x-auth-token': token
                }
            })
            .subscribe((res: any) => {
                this.classes = res
            })
    }

    ngOnInit (): void {}
}
