import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-teacher',
    templateUrl: './teacher.component.html',
    styleUrls: ['./teacher.component.scss']
})
export class TeacherComponent implements OnInit {
    teachers: any = []
    loading: boolean = true

    getCookie (name: string) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    constructor (private http: HttpClient) {
        const token: string = new Date().getTime().toString()

        const API_URL = environment.API_URL
        this.http
            .get(`${API_URL}/api/v1/contentapis?type=getTeachers`, {
                headers: {
                    'x-auth-token': token
                }
            })
            .subscribe((res: any) => {
                this.teachers = res
                this.loading = false
            })
    }

    ngOnInit (): void {}
}
