<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div *ngIf="!loading">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>الأخبار والمدونات </li>
                </ul>
                <h2>الأخبار والمدونات </h2>
            </div>
        </div>
        <div class="shape2"><img src="assets/img/instructor-home/banner/shape3.png" alt="image"></div>
    </div>

    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6" *ngFor="let blog of blogs?.data">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a [routerLink]="'/single-blog/'+ blog?.id" class="d-block">
                                <img [src]="blog?.image" [alt]="blog?.title" [title]="blog?.title" />
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/blog-1" class="category"> {{blog?.type}}</a>
                            <h3><a [routerLink]="'/single-blog/'+ blog?.nid">{{blog?.title}}</a></h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li> </li>
                                <li><i class='flaticon-calendar'></i> {{blog?.created}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a routerLink="/blogs" *ngIf="pageIndex != 0" class="prev page-numbers"><i
                                class='bx bx-chevrons-right'></i></a>
                        <span class="page-numbers current" aria-current="page">{{pageIndex +1}}</span>
                        <!-- blogs?.total -->
                        <!-- if total > 10 map page number -->
                        <a routerLink="/blogs" class="page-numbers" *ngFor="let i of pages">{{i}}</a>
                        <a routerLink="/blogs" class="next page-numbers" *ngIf="!blogs?.isLastPage"><i
                                class='bx bx-chevrons-left'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>