import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-faq',
    templateUrl: './payCard.component.html',
    styleUrls: ['./payCard.component.scss']
})
export class PayCardComponent implements OnInit {
    pos: any = []
    cities: any = []
    citiesOrg: any = []
    showmappopup: boolean = false
    mapData: any = {}
    cityValue: any = 'عمان'
    loading: boolean = true
    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private http: HttpClient
    ) {
        this.title.setTitle(route.snapshot.data.title)
        //https://cms.hemamacademy.com/ar/api/v1/pos
        const API_URL = environment.API_URL + '/api/v1/pos'
        this.http.get(API_URL).subscribe((data: any) => {
            if (data.length === 0) {
                return
            }
            this.citiesOrg = data

            if (this.cityValue == 'all') {
                this.pos = data
            } else {
                this.pos = data.filter((city: any) => {
                    return city.field_city == this.cityValue
                })
            }
            let cities = data.map((city: any) => {
                return city.field_city
            })
            this.cities = cities
            this.loading = false
        })
    }

    showMap (htmlcode: any) {
        //get element by id
        // htmlcode = "<section class=\"google-map-field\">\n    <h2>مكتبة الطالب</h2>\n      <div class=\"map-container\" style=\"width:100%;height:450px;\" data-controls-show=\"true\" data-traffic=\"false\" data-marker-show=\"true\" data-marker-icon=\"\" data-type=\"hybrid\" data-lat=\"31.873954238408\" data-lon=\"36.002183861055\" data-zoom=\"15\" data-infowindow=\"\"></div>\n</section>\n"

        htmlcode = htmlcode.toString()

        //get lat and lon from htmlcode
        let lat = htmlcode.match(/data-lat="([^"]*)"/)[1]
        let lon = htmlcode.match(/data-lon="([^"]*)"/)[1]

        this.mapData = {
            lat: parseFloat(lat),
            lon: parseFloat(lon)
        }
        this.showmappopup = true
    }
    searchPos (k: any) {
        let search = k.target.value
        this.pos = this.citiesOrg.filter((city: any) => {
            return city.field_state.includes(search)
        })
    }

    selectCity (city: any) {
        let val = city.target.value
        if (val == 'all') {
            this.pos = this.citiesOrg
            this.cityValue = 'all'
            return
        }

        this.pos = this.citiesOrg.filter((city: any) => {
            this.cityValue = val
            return city.field_city == val
        })
    }

    ngOnInit (): void {}
}
