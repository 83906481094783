import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-blog-style-one',
    templateUrl: './blog-style-one.component.html',
    styleUrls: ['./blog-style-one.component.scss']
})
export class BlogStyleOneComponent implements OnInit {
    blogs: any = []
    loading: boolean = true
    pageIndex: any = 0
    pages: any = []
    getBlogs () {
        this.loading = true
        this.blogs = []
        const API_URL =
            environment.API_URL +
            '/api/v1/blogs?page=' +
            this.pageIndex +
            '&type=getAllBlogsPagination'
        this.http.get(API_URL).subscribe((data: any) => {
            if (!data.status) {
                // navigate to 404
                this.router.navigate(['/404'])
            }
            this.blogs = data
            this.loading = false

            let pages = []
            if (data.total > 10) {
                // every 10 items make a page
                for (let i = 0; i < data.total / 10; i++) {
                    pages.push(i + 1)
                }
            }
            //change title
            this.title.setTitle(
                'الأخبار والمدونات الصفحة ' +
                    (this.pageIndex + 1) +
                    ' | ' +
                    environment.siteName
            )

           
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private http: HttpClient,
        private router: Router
    ) {
        this.getBlogs()
    }
    ngOnInit (): void {}

    changePage (index: number) {}
}
