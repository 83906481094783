import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    blogs: any = []
    constructor (public router: Router, private http: HttpClient) {
        const API_URL = environment.API_URL + '/api/v1/blogs-home'
        this.http.get(API_URL).subscribe((data: any) => {
            if (data.length === 0) {
                return
            }
            this.blogs = data
        })
    }

    ngOnInit (): void {}
}
