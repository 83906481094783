import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-teacher-page',
    templateUrl: './teacher-page.component.html',
    styleUrls: ['./teacher-page.component.scss']
})
export class TeacherPageComponent implements OnInit {
    teacherData: any = {}
    constructor (private http: HttpClient, private router: Router, private title: Title, private route: ActivatedRoute) {
        const url = this.router.url
        const id = url.split('/').pop()

        const token: string = new Date().getTime().toString()
        const API_URL = environment.API_URL
        this.http
            .get(
                `${API_URL}/api/v1/contentapis?type=getTeacherDataById&id=${id}`,
                {
                    headers: {
                        'x-auth-token': token
                    }
                }
            )
            .subscribe((res: any) => {
                this.teacherData = res
                this.title.setTitle(this.teacherData.name + ' | ' + environment.siteName)
            })
    }
    ngOnInit (): void {}

    // Tabs
    currentTab = 'tab1'
    currentTab2 = 'tab-pane1'
    currentTab3 = 'tab-pane-pane1'
    switchTab (event: MouseEvent, tab: string) {
        event.preventDefault()
        this.currentTab = tab
    }
    switchTab2 (event: MouseEvent, tabPane: string) {
        event.preventDefault()
        this.currentTab2 = tabPane
    }
    switchTab3 (event: MouseEvent, tabPane1: string) {
        event.preventDefault()
        this.currentTab3 = tabPane1
    }
}
