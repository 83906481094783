<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!loading">
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>المواد الدراسية</li>
                    <li>{{classes.name}}</li>
                </ul>
                <h2>المواد الدراسية  {{classes.name}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
    </div>

    <div class="categories-area pt-100 pb-70">
        <div class="container">
            <div class="content text-center" *ngIf="subjects.length == 0 && !loading">
                <!-- svg not found -->
                <div class="svg-wrap">
                    <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z" />
                    </svg>
                </div>
                <h3>لا يوجد مواد دراسية حاليا</h3>
            </div>
            <!-- map in subjects array -->
            <div class="row" *ngIf="subjects.length > 0">
                <div class="col-lg-2 col-sm-6 col-md-6" *ngFor="let subject of subjects">
                    <div class="single-categories-box">
                        <img [src]="subject.photo" alt="image">
                        <div class="content">
                            <h3>{{subject.name}}</h3>
                        </div>
                        <a [routerLink]="'subject/'+subject.id" class="link-btn"></a>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>