import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'src/environments/environment.prod'

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    contactForm: any = {}
    error: any = ''
    pageData: any = []

    constructor (
        private route: ActivatedRoute,
        private title: Title,
        private formBuilder: FormBuilder,
        private http: HttpClient
    ) {
        this.title.setTitle(this.route.snapshot.data.title)
        //https://cms.hemamacademy.com/ar/api/v1/countact_us_info
        const API_URL = environment.API_URL
        this.http
            .get(API_URL + '/api/v1/countact_us_info')
            .subscribe((response: any) => {
                this.pageData = response
            })
    }
    ngOnInit (): void {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: [
                '',
                [Validators.required, Validators.pattern('[0-9]{9,10}')]
            ],
            message: [
                '',
                [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(500)
                ]
            ]
        })
    }

    bgImage = [
        {
            img: 'assets/img/register-shape.jpg'
        }
    ]

    submit () {
        if (this.contactForm.valid && this.contactForm.dirty) {
            const API_URL = environment.API_URL
            this.http
                .post(API_URL + '/api/v1/contactForm?type=sendMessage', {
                    name: this.contactForm.value.name,
                    email: this.contactForm.value.email,
                    mobile: this.contactForm.value.mobile,
                    message: this.contactForm.value.message
                })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.error = true
                        this.contactForm.reset()
                    } else {
                        this.error = false
                        this.contactForm.reset()
                    }
                })
        }
    }
}
