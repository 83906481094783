<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>تواصل معنا</li>
            </ul>
            <h2> تواصل معنا</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title"> تواصل معنا</span>
                    <h2> نحن هنا لمساعدتك</h2>
                    <ul>
                        <!-- pageData map-->
                        <li *ngFor="let item of pageData" class="p-0">  
                            <h3>{{item.name}}</h3>
                            <p [innerHTML]="item.description__value"></p>   
                        </li>
                         
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2> اترك لنا رسالة</h2>
                    <p> نحن هنا لمساعدتك في أي شيء تحتاجه. لا تتردد في الاتصال بنا</p>
                    <form (ngSubmit)="submit()" [formGroup]="contactForm">

                        <div class="form-group">
                            <label class="text-white">الاسم </label>
                            <input type="text" class="form-control" placeholder="الاسم " formControlName="name">
                            <div *ngIf="contactForm?.get('name')?.invalid && contactForm?.get('name')?.touched"
                                class="text-white">
                                الاسم غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="text-white">البريد الإلكتروني</label>
                            <input type="email" formControlName="email" class="form-control"
                                placeholder="البريد الإلكتروني">
                            <div *ngIf="contactForm?.get('email')?.invalid && contactForm?.get('email')?.touched"
                                class="text-white">
                                البريد الإلكتروني غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="text-white"> رقم الهاتف</label>
                            <div class="input-group">
                                <input type="email" formControlName="mobile" class="form-control"
                                    placeholder="  رقم الهاتف">

                            </div>
                            <div *ngIf="contactForm?.get('mobile')?.invalid && contactForm?.get('mobile')?.touched"
                                class="text-white">
                                رقم الهاتف غير صحيح
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="text-white">الرسالة</label>
                            <textarea formControlName="message" rows="6" class="form-control"
                                placeholder="الرسالة"></textarea>
                            <div *ngIf="contactForm?.get('message')?.invalid && contactForm?.get('message')?.touched"
                                class="text-white">
                                الرسالة غير صحيحة
                                <div *ngIf="contactForm?.get('message')?.errors?.required" class="text-white">
                                    الرسالة مطلوبة
                                    <div *ngIf="contactForm?.get('message')?.errors?.minlength" class="text-white">
                                        الرسالة يجب أن تحتوي على 10 حروف على الأقل
                                        <div *ngIf="contactForm?.get('message')?.errors?.maxlength" class="text-white">
                                            الرسالة يجب أن تحتوي على 100 حرف كحد أقصى
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="default-btn"
                            [disabled]="contactForm.invalid || contactForm.pristine || contactForm.pending">
                            إرسال الرسالة
                        </button>

                        <!-- if error = no then suc else show error msg-->
                        <div *ngIf="error === true" class="text-white bg-success p-2 mt-2">
                            تم إرسال الرسالة بنجاح
                        </div>
                        <div *ngIf="error === false" class="text-white bg-danger p-2 mt-2">
                            فشل إرسال الرسالة
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>